<template>
  <div>
    <Widget
      v-if="loaded"
      ref="widget"
      :config="config"
      :height="height"
      @click="onClick($event)"
      :isLoading="loading"
      :chartOptions="chartOptions"
      :series="series"
      @loading="loading = $event"
      type="bar"
    >
      <template v-slot:title> Предстоящие расходы </template>
    </Widget>
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/chart"),
  },
  props: {
    height: { type: Number, default: 0 },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      loaded: false,
      details: [],
      series: [],
      chartOptions: {
        dataLabels: {
          formatter: (num) => {
            if (num >= 1000000) {
              return (num / 1000000).toFixed(1) + "M";
            }
            if (num >= 1000) {
              return (num / 1000).toFixed(1) + "K";
            }
            return num.toString();
          },
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: !true,
          },
          zoom: {
            enabled: !true,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: "end", // 'around', 'end'
            borderRadiusWhenStacked: "last", // 'all', 'last'
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          }, 
        },
        xaxis: {},
        yaxis: {},
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            //            return "";
            const data = w.config.xaxis.categories[dataPointIndex];
            let res = `Дата: ${data}`;
            const detail = w.config.xaxis.details;
            detail.forEach((d) => {
              if (d.title) {
                res += (res ? "<br>" : "") + `${d.title}: ${d.data[dataPointIndex]}`;
              }
            });
            return `<div class="tooltip" style="margin:10px">${res}</div>`;
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    loading: {
      get() {
        return this.isLoading;
      },
      set(v) {
        this.$emit("loading", v);
      },
    },
  },
  methods: {
    async fitchData() {
      const ds = new Date();
      const de = new Date().addDays(50);
      let res = await this.$axios.post("report_sql/filters", { name: "getFuturePaymentList", params: { ds: ds.date, de: de.date } });
      let series = [
        { name: "total", data: [] },
        { name: "other", title: "Прочее", data: [] },
        { name: "buying", title: "Покупки", data: [] },
        { name: "work", title: "Работы", data: [] },
        { name: "loan", title: "Кредит", data: [] },
      ];
      let dates = [];
      let data = res.data.data;

      let i = 0;
      let v_max = 0;
      for (let d = new Date(ds); d < de; d.setDate(d.getDate() + 1)) {
        if (i < 7) {
          let v = 0;
          let s = [0, 0, 0, 0, 0];
          data
            .filter((el) => new Date(el.date).date == d.date)
            .forEach((el) => {
              const idx = series.findIndex((s) => s.name == el.table_name) || 1;
              v += el.value;
              s[idx] += el.value;
            });
          if (v) {
            series[0].data.push(v);
            series[1].data.push(s[1] || 0);
            series[2].data.push(s[2] || 0);
            series[3].data.push(s[3] || 0);
            series[4].data.push(s[4] || 0);
            v_max = v > v_max ? v : v_max;
            i++;
            dates.push(d.date);
          }
        }
      }
      console.log({ dates, series });

      this.series = [series[0]];
      // this.series = series;
      this.details = series;
      this.chartOptions.xaxis = {
        details: series,
        labels: {
          show: true,
          rotate: -90,
          rotateAlways1: Boolean,
          formatter: function (value) {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            //   return `${day}.${month}`;
            return date.date;
          },
        },
        type1: "datetime",
        categories: [...dates],
      };
      this.chartOptions.yaxis = {
        show: false,
        min: 10000,
        max: v_max,
        logarithmic: true,
        logBase: 10,
        labels: {
          formatter: (num) => {
            if (num >= 1000000) {
              return (num / 1000000).toFixed(1) + "M";
            }
            if (num >= 1000) {
              return (num / 1000).toFixed(1) + "K";
            }
            return num.toFixed(1).toString();
          },
        },
      };
      this.loaded = true;
    },
  },
};
</script>
